<template>
  <div class="board tw-flex tw-flex-col tw-overflow-hidden tw-h-full">
    <HeaderPages :title="$t('Board') || ''" />

    <div class="flex-grow-1 border tw-rounded-3xl tw-py-4 tw-pl-4 tw-px-4 tw-h-full tw-overflow-hidden">
      <os-loader v-if="isLoadingBoard" class="mt-2" />
      <div
        v-else
        :class="{'flex-column': isHorizontal}"
        class="tw-flex tw-flex-grow tw-gap-4 tw-items-start tw-w-full tw-h-full overflow-auto board-scroll"
      >
        <div :class="{'tw-w-full tw-flex-col': isHorizontal}" class="tw-flex tw-flex-grow tw-gap-4 tw-items-start">
          <div
            v-for="(column, index) in filterBoard"
            :key="index"
            :class="isHorizontal ? 'tw-w-full tw-flex-col tw-pb-1.5' : 'w-300 tw-items-start'"
            class="tw-px-3 tw-pt-3 tw-bg-gray-100 tw-rounded-2xl"
          >
            <div :data-status-id="column.status" class="d-flex flex-column position-relative">
              <h5 class="tw-font-semibold tw-text-base tw-mb-4 tw-ml-1 tw-text-gray-600" style="top: 0">
                {{ formatTitle($t(column.status)) }} {{ `(${column.tasks.length})` }}
              </h5>

              <p v-if="column.tasks.length === 0" class="tw-text-base tw-mb-4 tw-ml-1 tw-text-gray-300 tw-text-center">
                Empty
              </p>

              <draggable
                v-model="column.tasks"
                :force-fallback="true"
                :scroll="true"
                :scroll-sensitivity="150"
                :scroll-speed="10"
                animation="150"
                class="list-group list-group-flush flex-grow-1"
                group="tasks"
                tag="ul"
                @change="startDrop"
                @end="changeTaskStatus($event)"
              >
                <b-list-group-item
                  v-for="(task, index) in column.tasks"
                  :key="index"
                  :class="{'d-flex flex-row align-items-center list-group-row': isHorizontal}"
                  :data-task-id="task.id"
                  class="cursor-grab p-0 tw-mb-3 tw-rounded-lg overflow-hidden tw-shadow-none tw-border-none"
                  tag="div"
                >
                  <div
                    :class="{
                      'tw-bg-red-400': task.priority === 'critical',
                      'tw-bg-orange-400': task.priority === 'high',
                      'tw-flex-col w-100': !isHorizontal,
                      'h-100': isHorizontal,
                    }"
                    :style="!isHorizontal ? 'height: 5px' : 'width: 5px'"
                  ></div>
                  <div :class="isHorizontal ? 'tw-flex tw-flex-grow tw-py-2 tw-px-3' : 'tw-p-4'">
                    <a
                      v-tippy="{content: task.subject, delay: [800, 0]}"
                      :class="{'tw-flex-grow': isHorizontal}"
                      :href="$router.resolve({name: 'task', params: {id: task.id}}).href"
                      class="tw-flex tw-justify-between tw-items-center tw-flex-nowrap tw-p-0 tw-cursor-pointer"
                    >
                      <b-card-title
                        class="font-weight-bold font-small-3 title_restriction d-flex align-items-center text-primary-hover mb-0"
                      >
                        <!--                        <span class="text-nowrap"></span>-->
                        <span class="ml-25">#{{ task.id }} {{ task.subject }}</span>
                      </b-card-title>
                    </a>
                    <div
                      :class="['text-' + checkDate(task.due_date, column.status), {'mt-50': !isHorizontal}]"
                      class="d-flex align-items-center font-weight-bold font-small-2"
                    >
                      <feather-icon :icon="'ClockIcon'" class="mr-25" />
                      {{ formatDueDate(task.due_date) }}
                    </div>
                    <div
                      :class="isHorizontal ? 'ml-50 align-items-center' : 'mt-1 align-items-end'"
                      class="d-flex justify-content-between"
                    >
                      <div>
                        <b-badge
                          v-if="task.task_type === 'task'"
                          class="mr-25 font-small-2 text-uppercase"
                          variant="light-primary"
                        >
                          {{ task.task_type }}
                        </b-badge>
                        <b-badge
                          v-if="task.task_type === 'bug'"
                          class="mr-25 font-small-2 text-uppercase"
                          variant="light-danger"
                        >
                          {{ task.task_type }}
                        </b-badge>
                        <b-badge
                          v-if="task.assigned['worker_type']"
                          class="font-small-2 text-uppercase"
                          variant="light-secondary"
                        >
                          {{ task.assigned['worker_type'] }}
                        </b-badge>
                      </div>
                      <BAvatar
                        v-tippy="task.assigned.title"
                        :class="{'ml-50': isHorizontal}"
                        :src="task.assigned.avatar"
                        :text="avatarText(task.assigned.title)"
                        size="30"
                        variant="light-primary"
                      />
                    </div>
                  </div>
                </b-list-group-item>
              </draggable>
            </div>
          </div>
        </div>

        <!-- Создание нового статуса -->

        <!--                <div-->
        <!--                    class="p-75 w-300"-->
        <!--                >-->
        <!--                    <div-->
        <!--                        class="h-100"-->
        <!--                    >-->
        <!--                        <h5-->
        <!--                            class="font-weight-bold mb-2 d-flex align-items-center cursor-pointer"-->
        <!--                            @click="showNewColumnForm = true"-->
        <!--                        >-->
        <!--                            <feather-icon-->
        <!--                                icon="PlusIcon"-->
        <!--                                class="mr-50 font-medium-1"-->
        <!--                            />-->
        <!--                            Add new-->
        <!--                        </h5>-->
        <!--                        <b-form-input-->
        <!--                            v-if="showNewColumnForm"-->
        <!--                            class="rounded-nrm"-->
        <!--                            placeholder="Add Board Title"-->
        <!--                            v-model="newColumnTitle"-->
        <!--                        ></b-form-input>-->
        <!--                        <div class="d-flex mt-1" v-if="showNewColumnForm">-->
        <!--                            <b-button-->
        <!--                                size="sm"-->
        <!--                                variant="primary"-->
        <!--                                class="mr-75"-->
        <!--                                @click="addColumn"-->
        <!--                            >Add-->
        <!--                            </b-button>-->
        <!--                            <b-button-->
        <!--                                size="sm"-->
        <!--                                variant="outline-secondary"-->
        <!--                                @click="showNewColumnForm = false"-->
        <!--                            >Cancel-->
        <!--                            </b-button>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {BAvatar, BBadge, BCardTitle, BListGroupItem, VBTooltip} from 'bootstrap-vue';
import draggable from 'vuedraggable';

import moment from 'moment';
import {formatTitle} from '@/assets/scripts/scripts';
import BoardService from '@/services/board.service';
import ProjectsService from '@/services/projects.service';
import {avatarText} from '@core/utils/filter';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import HeaderPages from '@/components/HeaderPages.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import OsLoader from '@/components/OsLoader.vue';

export default {
  components: {
    OsLoader,
    HeaderPages,
    BAvatar,
    BListGroupItem,
    BBadge,
    BCardTitle,
    draggable,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoadingBoard: true,
      selectedProject: '',
      selectedUser: '',
      currentBoard: [],
      filterBoard: [],
      newColumnTitle: '',
      showNewColumnForm: false,
      dropTaskId: null,
      to: null,
      from: null,
      options: {
        scrollSensitivity: 200,
        forceFallback: true,
        scroll: true,
        bubbleScroll: true,
      },
    };
  },
  async mounted() {
    ProjectsService.getProjects();
    const project = localStorage.getItem('selected_project') || {};
    if (project !== 'undefined' && project !== undefined && project !== null && project !== 'null') {
      // eslint-disable-next-line no-prototype-builtins
      if (JSON.parse(project).hasOwnProperty('id')) {
        await BoardService.getProjectStatuses(JSON.parse(project).id);
        this.getTasks();
      }
    }
  },
  computed: {
    updateStatuses() {
      return this.$store.state.osBoard.selectedProject;
    },
    selectedSprint() {
      return this.$store.state.osBoard.selectedSprint;
    },
    isHorizontal() {
      return this.$store.state.board.itemView === 'list-view';
    },
    board: {
      get: () => store.state.board.statuses,
      set: val => {
        store.commit('board/GET_STATUSES', val);
      },
    },
    isMyTasks() {
      return this.$store.state.board.isMyTasks;
    },
    isImPerformer() {
      return this.$store.state.board.isImPerformer;
    },
  },
  watch: {
    isMyTasks() {
      this.getTasks();
    },
    isImPerformer() {
      this.getTasks();
    },
    async selectedSprint() {
      await BoardService.getProjectStatuses(this.updateStatuses.id);
      await this.getTasks();
    },
    async updateStatuses() {
      await BoardService.getProjectStatuses(this.updateStatuses.id);
      this.getTasks();
    },
  },
  methods: {
    avatarText,
    getTasks() {
      this.isLoadingBoard = false;
      this.isMyTasks || this.isImPerformer
        ? (this.filterBoard = this.board.map(el => {
            return {
              ...el,
              tasks: el.tasks.filter(el => this.imAssigneeOrPerformer(el, this.$store.state.osBoard.user.id)),
            };
          }))
        : (this.filterBoard = this.board);
    },
    isAssigned(assigned) {
      return assigned !== null ? assigned.id : null;
    },
    imAssigneeOrPerformer(item, user) {
      const {isMyTasks, isImPerformer} = this.$store.state.board;
      return (
        (isMyTasks ? this.isAssigned(item.assigned) === user : true) &&
        (isImPerformer ? this.isAssigned(item.performer) === user : true)
      );
    },
    startDrop(e) {
      // eslint-disable-next-line no-prototype-builtins
      if (e.hasOwnProperty('added')) {
        this.dropTaskId = e.added.element.id;
      }
    },
    changeTaskStatus(e) {
      this.from = e.from.parentElement.dataset['statusId'];
      this.to = e.to.parentElement.dataset['statusId'];
      this.from !== this.to
        ? BoardService.changeTaskStatus(this.dropTaskId, this.to).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Board',
                icon: 'BellIcon',
                text: 'Task status changed',
              },
            });
          })
        : null;
    },
    checkDate(date, status) {
      if (status === 'done') return 'light-2';
      let now = moment().unix(),
        format_date = moment(date, 'YYYY-MM-DD HH:mm').unix(),
        addOneDay = moment(date, 'YYYY-MM-DD HH:mm').add(1, 'day').unix();
      if (addOneDay > now && now > format_date) return 'warning';
      if (now > addOneDay) return 'danger';
      if (now < addOneDay) return 'secondary';
    },
    formatDueDate(date) {
      moment.locale(this.$i18n.locale);
      return moment(date, 'YYYY-MM-DD HH:mm').format(this.$i18n.locale === 'en' ? 'MM/DD/YYYY' : 'DD.MM.YYYY');
    },
    formatTitle,
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/style.scss';

.list-group-item {
  transform: unset;
  transition: unset;

  &.list-group-row {
    padding: 6px 18px;
    margin-bottom: 0.4rem !important;
  }
}

.flip-list-move {
  transition: transform 1s;
}

.board .card {
  margin-bottom: 1rem;

  &.sortable-ghost {
    border: 1.5px dashed $primary;
  }

  .list-group {
    border-radius: 0.65rem !important;
    transition: background 0.2s ease;
  }

  .list-group:hover {
    background: #608eb008;
  }
}

.text_restriction {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
}

.title_restriction {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  box-orient: vertical;
}

.board input {
  padding: 4px 6px !important;
  height: auto;
}

.board .nav-link-custom {
  padding: 0 0 0 0.45rem !important;
  font-size: 1.1rem !important;
  color: #b9b9c3 !important;
}

@media screen and (min-width: 768px) {
  .board .card:hover {
    transform: unset;
    box-shadow: rgb(34 41 47 / 25%) 0 4px 25px 0;
  }
}
</style>
